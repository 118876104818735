


















import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

import PageBanner from "../components/ui/PageBanner.vue";
import ProjectsArticleNav from "@/components/project/ProjectsArticleNav.vue";

import { projects } from "@/mock/projects";

@Component({
  components: { PageBanner, ProjectsArticleNav }
})
export default class CaseStudiesPage extends Vue {
  projects = projects;
}
